




















































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { isArray, sortBy } from 'lodash';
import { AgGridVue } from '@ag-grid-community/vue';
import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { plainToClass } from 'class-transformer';
import CrmAppBar from '@/components/crm/app-bar.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { UserTypeEnum } from '@/enums/crm/user-type.enum';
import DataGridFilter from '@/components/data-grid-filter.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import dayjs from '@/plugins/dayjs';
import SettingsService from '@/services/crm/settings.service';
import ContactService from '@/services/crm/contact.service';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ActivityService from '@/services/crm/activity.service';
import UserModel from '@/models/user.model';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import { DateHelper } from '@/utils/helpers/date-helper';
import Tooltip from '@/components/tooltip.vue';
import ConversationService from '@/services/crm/conversation.service';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import ConversationModel from '@/models/crm/conversation.model';
import { GridHelper } from '@/utils/helpers/grid-helper';
import ContentDialog from '@/components/content-dialog.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import CrmChatHistoryMessagesViewer from '../../components/crm/chat-history-messages-viewer.vue';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';

type DataGridFilterConfig = {
  periodType: PeriodTypeEnum;
  period: (Date | undefined)[];
  status: StatusEnum | null;
  contactType: ContactTypeEnum[] | null;
  originType: number[] | null;
  template: number[] | null;
  contactName: string | null;
  contactNumber: string | null;
  department: ConversationDepartmentModel[] | null;
  attendant: number[] | null;
  historyType: HistoryTypeModel[] | null;
  protocolNumber: string | null;
};

enum PeriodTypeEnum {
  OpenDate = 'OPEN_DATE',
  ClosingDate = 'CLOSING_DATE',
}

enum StatusEnum {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

enum ContactTypeEnum {
  Client = 'CLIENT',
  Prospect = 'PROSPECT',
  Mistake = 'MISTAKE',
  Justified = 'JUSTIFIED',
}

enum OriginTypeEnum {
  Active = 2,
  Receptive = 1,
}

interface ISelectOption<T> {
  code: T;
  description: string;
}

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    CrmAppBar,
    AgGridWrapper,
    AgGridVue,
    Tooltip,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
  },
})
export default class CrmChatReport extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  settings: SettingsModel | null = null;

  userContactInfo: UserContactInfo | null = null;

  dialogConfig: IKeyValue<IDialogConfig> = {
    history: {
      show: false,
      conversation: null,
    },
    export: {
      show: false,
      exportAll: false,
      exportCurrent: true,
    },
  };

  // #region Grid

  grid: GridReadyEvent | null = null;

  gridClient: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    itemsPerPage: 30,
    columnDefs: [
      GridHelper.getSelectionColDef({ headerCheckboxSelection: false }),
      {
        headerName: `${this.$t('crm.view.chatReport.grid.openDate')}`,
        colId: 'openDate',
        field: 'dataInicio',
        maxWidth: 130,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataInicio || params.data.dataInclusao || params.data.dataUltimaMensagem;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (params.value && params.value !== 'Invalid Date') {
            return DateHelper.formatToLocale(params.value, 'dateTime');
          }
          return '';
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.closeDate')}`,
        colId: 'closeDate',
        field: 'dataFechamento',
        maxWidth: 130,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataFechamento;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (params.value && params.value !== 'Invalid Date') {
            return DateHelper.formatToLocale(params.value, 'dateTime');
          }
          return '';
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.lastAttendance')}`,
        colId: 'lastAttendance',
        field: 'dataUltimaMensagem',
        maxWidth: 130,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataUltimaMensagem || params.data.dataInicio || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (params.value && params.value !== 'Invalid Date') {
            return DateHelper.formatToLocale(params.value, 'dateTime');
          }
          return '';
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.contactType')}`,
        colId: 'contactType',
        field: 'tipo',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            let type = params.data.tipo || '';
            if (params.data?.engano === 1) {
              type = 'mistake';
            } else if (params.data?.outraJustificativaFechamento === 1) {
              return this.btnCloseNotSavingContact;
            }
            return this.$t(`crm.view.chatReport.filter.${type.toLowerCase()}`).toString();
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.originType')}`,
        colId: 'OriginType',
        field: 'origemConversa',
        maxWidth: 155,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.template')}`,
        colId: 'Template',
        field: 'templateConversa',
        maxWidth: 200,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.legalName')}`,
        colId: 'legalName',
        sortable: false,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.tipo === 'CLIENT') {
              return params.data?.cliente?.nome || params.data?.cliente?.nomeFantasia;
            }
            if (params.data.tipo === 'PROSPECT') {
              return params.data?.prospect?.razaoSocial || params.data?.prospect?.nome;
            }
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.contato?.nome || params.data.numeroWhatsapp;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        cellClass: 'cell-grid-link',
        maxWidth: 150,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.department')}`,
        colId: 'department',
        field: 'departamento.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.historyType')}`,
        colId: 'historyType',
        field: 'tipoHistorico.nome',
        flex: 0.8,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chatReport.grid.screeningHistory')}`,
        colId: 'screeningHistory',
        field: 'historicoTriagem',
        cellClass: 'cell-grid-link',
        maxWidth: 150,
        sortable: true,
      },
    ],
  };

  serverSideDatasource: IServerSideDatasource = {
    getRows: this.getRows,
  };

  items: ConversationModel[] = [];

  itemsClient: ConversationModel[] = [];

  selected: ConversationModel[] = [];

  // #endregion

  // #region filters

  filterOpen = 1;

  multipleFilterChanged = false;

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  filters: DataGridFilterConfig = {
    periodType: PeriodTypeEnum.OpenDate,
    period: [dayjs().startOf('month').toDate(), dayjs().toDate()],
    status: null,
    contactType: [ContactTypeEnum.Client, ContactTypeEnum.Prospect],
    originType: [OriginTypeEnum.Active, OriginTypeEnum.Receptive],
    template: null,
    contactName: null,
    contactNumber: null,
    department: null,
    attendant: null,
    historyType: null,
    protocolNumber: null,
  };

  totalRows = 0;

  isExporting = false;

  periodTypeOptions: ISelectOption<PeriodTypeEnum>[] = [];

  statusOptions: ISelectOption<StatusEnum>[] = [];

  contactTypeOptions: ISelectOption<ContactTypeEnum>[] = [];

  originTypeOptions: ISelectOption<OriginTypeEnum>[] = [];

  templateOptions: ConversationTemplateModel[] = [];

  departmentOptions: ConversationDepartmentModel[] = [];

  attendantOptions: UserModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  // #endregion

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadFilterOptions();
      this.settings = await this.settingsService.getSettings();
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  private async getRows(params: IServerSideGetRowsParams) {
    const sortMapping = {
      openDate: 'c.dataInicio',
      closeDate: 'c.dataFechamento',
      lastAttendance: 'c.dataUltimaMensagem',
      contactType: 'c.tipo',
      contactName: 'c.contato.nome',
      protocol: 'c.protocolo',
      attendant: 'c.atendente.nome',
      department: 'c.departamento.nome',
      historyType: 'c.tipoHistorico.nome',
    };

    const page = this.isExporting ? 1 : (params.request.endRow || 1) / (this.gridSettings.itemsPerPage || 1);
    let orderBy: string | undefined;

    if (params.request.sortModel?.length) {
      const mappedSort = params.request.sortModel.map((x) => `${sortMapping[x.colId]} ${x.sort}`);

      orderBy = mappedSort.join(', ');
    }

    try {
      this.filters.period[0] = dayjs(this.filters.period[0]).startOf('day').toDate();
      this.filters.period[1] = dayjs(this.filters.period[1]).endOf('day').toDate();
      if (this.settings === null) this.settings = await this.settingsService.getSettings();
      if (this.userContactInfo === null) this.userContactInfo = await this.contactService.getLoggedUserContactInfo();

      if (this.userContactInfo !== null && !this.notNormalUser) {
        this.filters.attendant = [this.userContactInfo.id];
      }

      const response = await this.conversationService.queryConversations(
        page,
        this.isExporting ? -1 : this.gridSettings.itemsPerPage || 1,
        this.filters as unknown,
        orderBy,
      );

      this.totalRows = response.total;

      params.success({
        rowData: response.results,
        rowCount: response.total,
      });

      if (this.isExporting && this.gridClient) {
        const onlySelected = false;
        this.gridClient.api.setRowData(response.results);
        this.gridClient.api.exportDataAsExcel({
          onlySelected,
          allColumns: true,
          author: 'IBtech',
          sheetName: 'Conversas',
          fileName: ConversationService.generateConversationReportExportFilename(new Date()),
        });
      }
      this.gridClient?.api.setRowData([]);
      this.isExporting = false;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
      params.fail();
    }
  }

  onFilterChange(type: string): void {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    this.applyFilter();
  }

  async applyFilter(): Promise<void> {
    this.multipleFilterChanged = false;

    try {
      if (this.grid?.api) {
        this.grid.api.deselectAll();
        this.selected = [];

        this.grid.api.paginationGoToFirstPage();
        this.grid.api.refreshServerSideStore({});
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ConversationModel[]): void {
    this.selected = selected;
  }

  onExport(): void {
    this.dialogConfig.export.show = true;
  }

  onExportConfirm(selected: ConversationModel[]): void {
    if (this.grid) {
      if (this.dialogConfig.export.exportAll) {
        this.isExporting = true;
        const loader = this.$loading.show();
        try {
          this.grid.api.purgeServerSideCache();
        } catch (err) {
          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
        }
      } else {
        const onlySelected = !!selected.length && this.items.length !== selected.length;
        const columnKeys = this.gridSettings.columnDefs
          .filter((x) => !x.checkboxSelection)
          .map((x) => x.colId || x.field || '');
        this.grid.api.exportDataAsExcel({
          onlySelected,
          columnKeys,
          allColumns: true,
          author: 'IBtech',
          sheetName: 'Conversas',
          fileName: ConversationService.generateConversationReportExportFilename(new Date()),
        });
      }
    }
    this.dialogConfig.export.show = false;
  }

  onExportAllChange(): void {
    if (this.dialogConfig.export.exportAll) {
      this.dialogConfig.export.exportCurrent = false;
    }
  }

  onExportCurrentChange(): void {
    if (this.dialogConfig.export.exportCurrent) {
      this.dialogConfig.export.exportAll = false;
    }
  }

  async onCellClick(event: IGridCellEvent<ConversationModel>): Promise<void> {
    if (!event.data || event?.colDef?.colId !== 'protocol') {
      return;
    }

    this.openHistoryConversationDialog(plainToClass(ConversationModel, event.data));
  }

  openHistoryConversationDialog(conversation: ConversationModel): void {
    if (!conversation) {
      return;
    }

    this.dialogConfig.history.show = true;
    this.dialogConfig.history.conversation = conversation;
  }

  onCloseHistoryDialogClose(): void {
    this.dialogConfig.history.show = false;
    this.dialogConfig.history.conversation = false;
  }

  onCloseExportDialogClose(): void {
    this.dialogConfig.export.show = false;
    this.dialogConfig.export.exportAll = false;
    this.dialogConfig.export.exportCurrent = true;
  }

  get btnCloseNotSavingContact(): string {
    const res = this.$route.query.btnFecharConversaSemSalvarContato;
    if (res) {
      return res.toString();
    }
    return '';
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore = ['periodType'];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get historyDialogTitle(): string {
    const protocol = (this.dialogConfig.history.conversation as ConversationModel)?.protocolo;
    return this.$t('crm.view.chatReport.dialog.history.title', { protocol }).toString();
  }

  get exportDialogTitle(): string {
    return this.$t('crm.view.chatReport.dialog.export.title').toString();
  }

  get notNormalUser(): boolean {
    return this.settings?.loggedUserType !== UserTypeEnum.Normal;
  }

  private async loadFilterOptions(): Promise<void> {
    this.periodTypeOptions = this.getPeriodTypes();
    this.statusOptions = this.getStatusOptions();
    this.contactTypeOptions = this.getContactTypeOptions();
    this.originTypeOptions = this.getOriginTypeOptions();

    const tasks = [
      this.getDepartments().then((result) => {
        this.departmentOptions = result;
      }),
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
      this.getHistoryTypes().then((result) => {
        this.historyTypeOptions = result;
      }),
      this.getTemplates().then((result) => {
        this.templateOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private getPeriodTypes(): ISelectOption<PeriodTypeEnum>[] {
    return [
      {
        code: PeriodTypeEnum.OpenDate,
        description: this.$t('crm.view.chatReport.filter.openDate').toString(),
      },
      {
        code: PeriodTypeEnum.ClosingDate,
        description: this.$t('crm.view.chatReport.filter.closingDate').toString(),
      },
    ];
  }

  private getStatusOptions(): ISelectOption<StatusEnum>[] {
    return [
      {
        code: StatusEnum.Open,
        description: this.$t('crm.view.chatReport.filter.open').toString(),
      },
      {
        code: StatusEnum.Closed,
        description: this.$t('crm.view.chatReport.filter.closed').toString(),
      },
    ];
  }

  private getContactTypeOptions(): ISelectOption<ContactTypeEnum>[] {
    const res = [
      {
        code: ContactTypeEnum.Client,
        description: this.$t('crm.view.chatReport.filter.client').toString(),
      },
      {
        code: ContactTypeEnum.Prospect,
        description: this.$t('crm.view.chatReport.filter.prospect').toString(),
      },
      {
        code: ContactTypeEnum.Mistake,
        description: this.$t('crm.view.chatReport.filter.mistake').toString(),
      },
    ];
    if (this.btnCloseNotSavingContact !== '') {
      res.push({
        code: ContactTypeEnum.Justified,
        description: this.btnCloseNotSavingContact,
      });
    }
    return res;
  }

  private getOriginTypeOptions(): ISelectOption<OriginTypeEnum>[] {
    return [
      {
        code: OriginTypeEnum.Active,
        description: this.$t('crm.view.chatReport.filter.activeConversation').toString(),
      },
      {
        code: OriginTypeEnum.Receptive,
        description: this.$t('crm.view.chatReport.filter.receptiveConversation').toString(),
      },
    ];
  }

  private async getDepartments(): Promise<ConversationDepartmentModel[]> {
    return this.conversationService.getDepartments();
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants();
  }

  private async getHistoryTypes(): Promise<HistoryTypeModel[]> {
    return sortBy(await this.activityService.getHistoryTypes(), 'nome');
  }

  private async getTemplates(): Promise<ConversationTemplateModel[]> {
    return sortBy(await this.conversationService.getTemplates(), 'waTemplateName');
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }
}
